* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button.react-multiple-carousel__arrow {
  opacity: 0.7;
}

button.react-multiple-carousel__arrow--right {
  right: calc(6% + 1px);
}

button.react-multiple-carousel__arrow {
  border-radius: 40%;
  min-width: 50px;
  min-height: 50px;
}

textarea:hover,
textarea:active,
textarea:focus {
  border-radius: 7px;
  outline: none !important;
  border: 1px solid #97da91;
}
